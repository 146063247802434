import "./style.scss";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next/dist/es/useTranslation";
import AccentContainer from "../../Container/_accent";
import Button from "../../Buttons/Common";
import Container from "../../Container";
import Heading from "../../Heading";
import LinkOnResource from "../../Links/LinkOnResource";
import { createClassName } from "../../../helpers";

const cn = createClassName("accentSecondaryBanner");

const AccentSecondaryBanner = (props) => {
  const { t } = useTranslation("strings");

  return (
    <AccentContainer>
      <Container className={`${cn({ wrap: true })} ${props.bannerClassName}`}>
        <div className={cn({ section: true })}>
          <Heading
            level={2}
            weight="bold"
            color="white"
            className={props.titleClassName}
          >
            {t("AccentSecondaryBanner.heading", { nonBreakingSpace: "\xa0" })}
          </Heading>
          <Heading level={3} color="white" className={props.subtitleClassName}>
            {t("AccentSecondaryBanner.title", { nonBreakingSpace: "\xa0" })}
          </Heading>
        </div>
        <div className={cn({ section: true, button: true })}>
          <div>
            <LinkOnResource
              href="/download-free-trial/"
              isVolume
              color="white"
              className={props.buttonClassName}
              isRel={false}
              withoutLocale={false}
            >
              {t("common:start-ft")}
            </LinkOnResource>
            {/* <Button
              size="large"
              handler={props.handler}
              className={props.buttonClassName}
              ariaLabel={props.buttonText}
            >
              {t("common:start-ft")}
            </Button> */}
            <p className={cn({ button: "description" })}>
              {t("AccentSecondaryBanner.button-description")}
            </p>
          </div>
        </div>
      </Container>
    </AccentContainer>
  );
};

AccentSecondaryBanner.propTypes = {
  bannerClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  handler: PropTypes.func,
};

export default AccentSecondaryBanner;
